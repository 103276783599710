import api from "@/config/api";
import { Member } from "@/types/utils";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction } from "react";
import { log } from "@/utils/logger";
import { useParams } from "react-router-dom";

const useModal = (
    memberState: { isAdding: boolean; email: string },
    setMemberState: Dispatch<SetStateAction<{ isAdding: boolean; email: string }>>,
) => {
    const { oid } = useParams<{ oid: string }>();
    const queryClient = useQueryClient();

    const onAdd = async () => {
        const email = memberState.email.trim();

        if (!email || !oid) return;

        try {
            await api.post<Member>(`/organizations/${oid}/members`, { data: { email } });
            queryClient.invalidateQueries({ queryKey: ["members", oid] });


            log.success(`Member ${email} has been successfully added.`);
        } catch (error) {
            log.error(error);
        }
        onClose();
    };

    const onClose = () => {
        setMemberState(prevState => ({ ...prevState, email: "", isAdding: false }));
    };

    return { onAdd, onClose };
};

export { useModal };
