import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Styled } from "./styles";
import { Button } from "@dippi/ui";
import OrganizationModal from "./modal";
import storage from "@/utils/storage";
import SearchIcon from "@mui/icons-material/Search";
import { useOrganizations } from "@/context/organizations";
import useQueryOrganizations from "@/hooks/queries/useQueryOrganizations";

const Organizations: React.FC = () => {
    const navigate = useNavigate();
    const { data, isLoading } = useQueryOrganizations();

    const { organizations, setOrganizations } = useOrganizations(state => state);

    const [modal, setModal] = useState(false);
    const openModal = () => setModal(true);
    const closeModal = () => setModal(false);

    const onSelect = (id: string) => {
        storage.setItem("oid", id);
        navigate(`/organization/${id}`);

        if (!data) return;

        setOrganizations(data);
    };

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const filtered =
            data?.filter(i => i.title.toLowerCase().includes(value.toLowerCase())) || [];
        setOrganizations(filtered);
    };

    if (isLoading) {
        return (
            <Styled.Container>
                <CircularProgress />
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={10}
            >
                <Typography marginBottom={0} variant="h1">
                    Organizations
                </Typography>
                <Styled.Search
                    placeholder="Search"
                    onChange={onSearch}
                    InputProps={{
                        endAdornment: <SearchIcon />,
                    }}
                />
            </Box>
            {Array.isArray(organizations) ? (
                <Styled.List>
                    {organizations.map(i => {
                        return (
                            <Styled.ListItem key={i._id} onClick={onSelect.bind(this, i._id)}>
                                <Typography variant="h4">{i.title}</Typography>
                                <Typography variant="body2">{i.companyId}</Typography>
                            </Styled.ListItem>
                        );
                    })}
                </Styled.List>
            ) : null}
            <Button sx={{ width: "100%", padding: "25px 0px" }} onClick={openModal}>
                Add new organization
            </Button>
            <OrganizationModal modal={modal} closeModal={closeModal} />
        </Styled.Container>
    );
};

export default Organizations;
