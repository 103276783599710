import { Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { Styled } from "../styles";
import constant from "@/utils/constant";
import { HelpIcon, Input } from "@dippi/ui";
import { constructBrandKey } from "@/utils/helpers";
import { useParams } from "react-router-dom";
import { Params } from "@/types/utils";
import { Forms } from "@/types/form";
import { isDefined } from "@/utils/validations";

const Base: React.FC = () => {
    const { control, setValue, watch } = useFormContext<Forms["Brand"]>();
    const title = watch("title");
    const timer = useRef<NodeJS.Timeout>();

    const { bid } = useParams<Params>();
    const isUpdate = isDefined(bid);

    const updateKey = useMemo(() => {
        return () => {
            timer.current && clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setValue("tag", constructBrandKey(title), { shouldValidate: true });
            }, 500);
        };
    }, [title]);

    useEffect(() => {
        if (!title || isUpdate) return;

        updateKey();
    }, [title]);

    return (
        <Styled.Container item container xs={12} gap={constant.GRID_SPACING}>
            <Grid item xs={12}>
                <Typography variant="h3">Brand information</Typography>
            </Grid>
            <Grid item lg={3.5} md={5} xs={12}>
                <Input testId="brand-name-input" name="title" control={control} label="Name" />
            </Grid>
            <Grid item lg={3.5} md={5} xs={12}>
                <Input testId="brand-tag-input" name="tag" control={control} label="Tag" />
            </Grid>
            <Grid item lg={3.5} md={5} xs={12}>
                <Input
                    testId="brand-email-input"
                    name="email"
                    control={control}
                    label="Email"
                    type="email"
                    InputProps={{
                        endAdornment: (
                            <HelpIcon
                                key="brand-email-help"
                                title="Brand email will be used in the webview when a product is scanned."
                            />
                        ),
                    }}
                />
            </Grid>
            <Grid item lg={3.5} md={5} xs={12}>
                <Input
                    testId="brand-website-input"
                    name="websiteURL"
                    control={control}
                    label="Website URL"
                    placeholder="https://example.com"
                />
            </Grid>
        </Styled.Container>
    );
};

export default Base;
