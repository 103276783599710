import { Grid, styled } from "@mui/material";

type TableItemProps = {
    validations: {
        isError: boolean;
        titleError?: boolean;
        countriesError?: boolean;
        websiteURLError?: boolean;
    };
};

type TableItemActionsProps = {
    validations: {
        isError: boolean;
    };
};

export const Styled = {
    TableHeader: styled(Grid)`
        padding: 10px;
        border: 1px solid ${props => props.theme.palette.secondary.light};
        display: flex;
        align-items: center;
    `,
    TableItem: styled(Grid, {
        shouldForwardProp: prop => prop !== "validations",
    })<TableItemProps>`
        padding: 10px;
        border: 1px solid
            ${props => {
                const { titleError, countriesError, websiteURLError } = props.validations;
                return titleError || countriesError || websiteURLError
                    ? props.theme.palette.error.main
                    : props.theme.palette.secondary.light;
            }};
        height: ${props => (props.validations.isError ? "60px" : "46px")};

        & .MuiFormHelperText-root {
            margin-top: unset;
        }
    `,
    TableItemActions: styled(Grid, {
        shouldForwardProp: prop => prop !== "validations",
    })<TableItemActionsProps>`
        border: 1px solid ${props => props.theme.palette.secondary.light};
        display: flex;
        align-items: center;
        height: ${props => (props.validations.isError ? "60px" : "46px")};
        padding: 0 10px;
    `,
};
