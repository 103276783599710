import { UncontrolledInput } from "@dippi/ui";
import { styled } from "@mui/material";

export const Styled = {
    Container: styled("div")<{ center?: boolean }>`
        width: 550px;
        margin: auto;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        justify-content: ${props => (props.center ? "center" : "unse")};
    `,
    List: styled("ul")`
        width: 100%;
        margin: 20px 0px;
        max-height: 50vh;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    `,
    ListItem: styled("li")`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        padding: 20px;
        margin: 10px 0px;
        border-radius: 8px;
        background-color: ${props => props.theme.palette.secondary.main};
        &:hover {
            background-color: ${props => props.theme.palette.primary.main};
        }
    `,
    Search: styled(UncontrolledInput)`
        & .MuiInputBase-root input {
            padding: 10px 8px;
        }
    `,
};
