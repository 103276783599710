import api from "@/config/api";
import { Forms } from "@/types/form";
import { log } from "@/utils/logger";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";

const useSubmit = () => {
    const { oid } = useParams();
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (form: Forms["Settings"]) => {
        try {
            setLoading(true);
            await api.put(`/organizations/${oid}`, { data: form });
            await Promise.all([
                queryClient.refetchQueries({ queryKey: ["organization", oid], exact: true }),
                queryClient.invalidateQueries({ queryKey: ["organizations"] }),
            ]);

            log.success("Company has been successfully updated.");
        } catch (e) {
            log.error(e);
        } finally {
            setLoading(false);
        }
    };

    return { onSubmit, loading };
};

export { useSubmit };
