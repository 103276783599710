import { auth } from "@/config/firebase";
import storage from "@/utils/storage";
import { User } from "firebase/auth";
import { create } from "zustand";

interface IntitialState {
    email: string | null;
    authenticated: boolean;
    globalLoading: boolean;
}

interface Actions {
    login(): void;
    logout(): void;
    setGlobalLoading: (bool: boolean) => void;
}

const initialState: IntitialState = {
    email: null,
    authenticated: false,
    globalLoading: false,
};

export const useAdmin = create<IntitialState & Actions>(set => ({
    ...initialState,
    setGlobalLoading: globalLoading => set({ globalLoading }),
    login: () => {
        const user = auth.currentUser as User;
        const { email } = user;
        set({ email, authenticated: true });
    },
    logout: () => {
        storage.removeItem("oid");
        set(initialState);
    },
}));
