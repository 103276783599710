import { MenuItem, styled, Button, ButtonProps } from "@mui/material";

export const Styled = {
    Container: styled("div")`
        height: 300px;
        aspect-ratio: 1;
        border-radius: 16px;
        border: 1px solid ${props => props.theme.palette.secondary.main };
        padding: 32px 24px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        transition:
            border-color 0.5s ease,
            color 0.5s ease;

        &:hover {
            border-color: ${props => props.theme.palette.secondary.light};
        }
    `,
    FileInput: styled("input")`
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        white-space: nowrap;
        width: 1;
    `,
    Feedback: styled("div")`
        display: flex;
        align-items: center;
    `,
    Placeholder: styled(Button)<ButtonProps>`
        max-height: none;
        border-radius: 8px;
        padding: 0;
        height: 150px;
        width: 150px;
        transition: 0.5s ease-in-out;
        background-color: ${props => props.theme.palette.secondary.dark};
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: ${props => props.theme.palette.secondary.main};
        }
    `,
    ChangeButton: styled(Button)<ButtonProps>`
        padding: 0px;
        color: white;
    `,
    Image: styled("img")`
        height: 150px;
        width: 150px;
        object-fit: contain;
    `,
    IconOverlay: styled("div")`
        background: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.5s ease-in-out;
    `,
    Camera: styled("img")`
        background: none;
        transition: opacity 0.5s ease-out;
    `,
    MenuItem: styled(MenuItem)`
        &:hover {
            & .MuiButtonBase-root {
                background: transparent;
            }
            & .MuiTypography-root {
                background: transparent;
            }
        }
    `,
};
