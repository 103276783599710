import { Forms } from "@/types/form";
import { Params } from "@/types/utils";
import { log } from "@/utils/logger";
import { isDefined, isString } from "@/utils/validations";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import api from "@/config/api";

const clearUnusedProperties = (data: Forms["Brand"]) => {
    if (!isString(data.email)) delete data.email;
    if (!isString(data.websiteURL)) delete data.websiteURL;
    if (!data.media) delete data.media;
    if (!data.socials || data.socials.length === 0) delete data.socials;
    if (!isString(data.description)) delete data.description;

    return data;
};

const useSubmit = () => {
    const { oid, bid } = useParams<Params>();
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const isUpdate = isDefined(bid);

    return useMutation({
        mutationFn: async (brand: Forms["Brand"]) => {
            const data = clearUnusedProperties(brand);
            const endpoint = isUpdate
                ? `/organizations/${oid}/brands/${bid}`
                : `/organizations/${oid}/brands`;

            if (isUpdate) return await api.put(endpoint, { data });
            else return await api.post(endpoint, { data });
        },
        onMutate: async newBrand => {
            await queryClient.cancelQueries({ queryKey: ["brands", oid] });

            const previousBrands = queryClient.getQueryData<Forms["Brand"][]>(["brands", oid]);

            queryClient.setQueryData<Forms["Brand"][]>(["brands", oid], brands => {
                if (isUpdate) {
                    return (
                        brands?.map(brand =>
                            brand.tag === newBrand.tag ? { ...brand, ...newBrand } : brand,
                        ) || []
                    );
                } else {
                    return brands ? [...brands, newBrand] : [newBrand];
                }
            });

            return { previousBrands };
        },
        onError: (error, _, context: { previousBrands?: Forms["Brand"][] } | undefined) => {
            queryClient.setQueryData(["brands", oid], context?.previousBrands);
            log.error(error);
        },
        onSuccess: () => {
            log.success(`Brand has been successfully ${isUpdate ? "updated" : "created"}.`);
            navigate(`/organization/${oid}`);

            queryClient.invalidateQueries({ queryKey: ["brands", oid] });
            queryClient.refetchQueries({ queryKey: ["subscription", oid] });
        },
    });
};

export { useSubmit };
