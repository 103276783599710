import schemas from "@/utils/schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useLayoutEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Styled } from "./styles";
import { Backdrop, Button } from "@dippi/ui";
import { Grid } from "@mui/material";
import { Forms } from "@/types/form";
import { Brand } from "@/types/entities";
import Base from "./base";
import Description from "./description";
import Socials from "./socials";
import Entities from "./entities";
import constant from "@/utils/constant";
import { useSubmit } from "./hooks";
import FileUpload from "@/components/file-upload";
import { Params, useNavigate, useParams } from "react-router-dom";

const getDefaultValues = (brand: Brand | undefined): Forms["Brand"] => {
    const values: Forms["Brand"] = {
        email: brand?.email ?? "",
        title: brand?.title ?? "",
        tag: brand?.tag ?? "",
        media: brand?.media ?? undefined,
        websiteURL: brand?.websiteURL ?? "",
        description: brand?.description ?? "",
        socials: brand?.socials ?? [],
        entities: brand?.entities ?? [],
    };

    return values;
};

interface Props {
    brand?: Brand;
}

const BrandForm: React.FC<Props> = props => {
    const { brand } = props;
    const navigate = useNavigate();

    const { bid, oid } = useParams<Params>();

    const methods = useForm<Forms["Brand"]>({
        resolver: yupResolver(schemas.brand),
        values: getDefaultValues(brand),
    });

    const { handleSubmit, formState } = methods;
    const { isDirty, isSubmitting } = formState;

    const { mutateAsync: onSubmit } = useSubmit();
    const SPACING = constant.GRID_SPACING;
    const submitBtnText = bid ? "Update" : "Create";

    const onCancel = () => {
        navigate(`/organization/${oid}`);
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <FormProvider {...methods}>
            <Styled.Form
                id="form-brand"
                onSubmit={handleSubmit(async data => await onSubmit(data))}
            >
                <Grid container spacing={SPACING} rowGap={SPACING}>
                    <Grid item container justifyContent="flex-end" gap={SPACING}>
                        <Button variant="outlined" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={!isDirty}>
                            {submitBtnText}
                        </Button>
                    </Grid>
                    <Grid container spacing={SPACING} rowGap={SPACING}>
                        <Styled.ImageContainer item lg={3}>
                            <FileUpload />
                        </Styled.ImageContainer>
                        <Grid item container xl={9} gap={SPACING}>
                            <Base />
                            <Description />
                            <Socials />
                            <Entities />
                        </Grid>
                    </Grid>
                </Grid>
                <Backdrop loading={isSubmitting} />
            </Styled.Form>
        </FormProvider>
    );
};

export default BrandForm;
