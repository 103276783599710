import React from "react";
import { useFormContext } from "react-hook-form";
import { Styled } from "../styles";
import { Grid, Typography } from "@mui/material";
import { useSocials } from "./hooks";
import constant from "@/utils/constant";
import { UncontrolledInput } from "@dippi/ui";
import { Forms } from "@/types/form";

const sx: React.CSSProperties = {
    opacity: 0.7,
    marginRight: "4px",
};

const Socials: React.FC = () => {
    const {
        watch,
        setValue,
        formState: { errors },
    } = useFormContext<Forms["Brand"]>();

    const {
        instagram,
        facebook,
        tiktok,
        youtube,
        linkedin,
        onChangeSocial,
        getSocialValidationMessage,
    } = useSocials(watch, setValue, errors);

    return (
        <Styled.Container item container xs={12} gap={constant.GRID_SPACING}>
            <Grid item xs={12}>
                <Typography variant="h3">Socials</Typography>
            </Grid>
            <Grid item lg={3.5} md={5} xs={12} display="flex" flexDirection="column">
                <UncontrolledInput
                    value={instagram}
                    onChange={e => onChangeSocial("https://instagram.com/", e.target.value)}
                    type="text"
                    fullWidth={true}
                    label="Instagram"
                    // @ts-expect-error
                    error={getSocialValidationMessage("instagram")}
                    InputProps={{
                        startAdornment: (
                            <Typography variant="body2" sx={sx}>
                                instagram.com/
                            </Typography>
                        ),
                    }}
                />
            </Grid>
            <Grid item lg={3.5} md={5} xs={12} display="flex" alignItems="center">
                <UncontrolledInput
                    value={facebook}
                    onChange={e => onChangeSocial("https://facebook.com/", e.target.value)}
                    type="text"
                    fullWidth={true}
                    label="Facebook"
                    // @ts-expect-error
                    error={getSocialValidationMessage("facebook")}
                    InputProps={{
                        startAdornment: (
                            <Typography variant="body2" sx={sx}>
                                facebook.com/
                            </Typography>
                        ),
                    }}
                />
            </Grid>
            <Grid item lg={3.5} md={5} xs={12} display="flex" alignItems="center">
                <UncontrolledInput
                    value={tiktok}
                    onChange={e => onChangeSocial("https://tiktok.com/", e.target.value)}
                    type="text"
                    fullWidth={true}
                    label="TikTok"
                    // @ts-expect-error
                    error={getSocialValidationMessage("tiktok")}
                    InputProps={{
                        startAdornment: (
                            <Typography variant="body2" sx={sx}>
                                tiktok.com/
                            </Typography>
                        ),
                    }}
                />
            </Grid>
            <Grid item lg={3.5} md={5} xs={12} display="flex" alignItems="center">
                <UncontrolledInput
                    value={youtube}
                    onChange={e => onChangeSocial("https://www.youtube.com/", e.target.value)}
                    type="text"
                    fullWidth={true}
                    label="Youtube"
                    // @ts-expect-error
                    error={getSocialValidationMessage("youtube")}
                    InputProps={{
                        startAdornment: (
                            <Typography variant="body2" sx={sx}>
                                youtube.com/
                            </Typography>
                        ),
                    }}
                />
            </Grid>
            <Grid item lg={3.5} md={5} xs={12} display="flex" alignItems="center">
                <UncontrolledInput
                    value={linkedin}
                    onChange={e =>
                        onChangeSocial("https://www.linkedin.com/company/", e.target.value)
                    }
                    type="text"
                    fullWidth={true}
                    label="LinkedIn"
                    // @ts-expect-error
                    error={getSocialValidationMessage("linkedin")}
                    InputProps={{
                        startAdornment: (
                            <Typography variant="body2" sx={sx}>
                                linkedin.com/company/
                            </Typography>
                        ),
                    }}
                />
            </Grid>
        </Styled.Container>
    );
};

export default Socials;
