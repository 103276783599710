import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, ListItemIcon, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Theme } from "@mui/system";
import { Logout, Swap } from "@dippi/icons";
import { useHeader } from "./hooks";
import { Styled } from "./styles";
import constant from "@/utils/constant";

const HeaderTop: React.FC = () => {
    const navigate = useNavigate();
    const { anchorEl, onOpenMenu, onCloseMenu, onLogout, onLogo } = useHeader();

    const isMenuActive = anchorEl !== null && {
        borderColor: (theme: Theme) => theme.palette.primary.main,
        "& .MuiIconButton-root": {
            color: (theme: Theme) => theme.palette.primary.main,
        },
    };

    const { dippiLogo, defaultAvatar } = constant.ASSETS;

    const onSwitch = () => {
        navigate("/organizations");
    };

    return (
        <Styled.Container>
            <Styled.Logo src={dippiLogo} alt="Dippi logo" onClick={onLogo} />

            <Styled.Dropdown sx={{ ...isMenuActive }}>
                <IconButton onClick={onOpenMenu} disableRipple={true}>
                    <img src={defaultAvatar} height={32} width={32} />
                    <ArrowDropDownIcon />
                </IconButton>
                <Styled.Menu
                    sx={{ mt: "35px" }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={onCloseMenu}
                    onClick={onCloseMenu}
                >
                    <MenuItem data-testid="menu-switch" onClick={onSwitch}>
                        <Styled.ItemContent textAlign="center">
                            <ListItemIcon>
                                <Swap />
                            </ListItemIcon>
                            <Typography>Switch</Typography>
                        </Styled.ItemContent>
                    </MenuItem>
                    <MenuItem onClick={onLogout}>
                        <Styled.ItemContent textAlign="center">
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            <Typography>Log out</Typography>
                        </Styled.ItemContent>
                    </MenuItem>
                </Styled.Menu>
            </Styled.Dropdown>
        </Styled.Container>
    );
};

export default HeaderTop;
