type StringKeys = "oid" | "bid";

function getItem(key: StringKeys): string | undefined;

function getItem<D>(key: StringKeys): D | undefined {
    const value = localStorage.getItem(key);

    if (!value) return undefined;
    return JSON.parse(value);
}

function setItem(key: StringKeys, value: string): void;

function setItem<D>(key: StringKeys, value: D) {
    localStorage.setItem(key, JSON.stringify(value));
}

function removeItem(key: StringKeys) {
    localStorage.removeItem(key);
}

function clear() {
    localStorage.clear();
}

export default {
    getItem,
    setItem,
    removeItem,
    clear,
};
