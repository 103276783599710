import React, { useState } from "react";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import constant from "@/utils/constant";
import { isArrayWithData } from "@/utils/validations";
import { Styled as BaseStyled } from "../styles";
import { Button } from "@dippi/ui";
import { Member } from "@/types/utils";
import MembersModal from "./members-modal";
import MemberRow from "./member-row";

interface Props {
    data: Member[] | undefined;
}

const Members: React.FC<Props> = ({ data }) => {
    const [memberState, setMemberState] = useState({
        isAdding: false,
        email: "",
    });

    return (
        <BaseStyled.Content item container xs={12} gap={constant.GRID_SPACING}>
            <Grid item xs={12}>
                <Typography variant="h3">Members</Typography>
            </Grid>
            <Grid item xs={12}>
                {isArrayWithData(data) ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>N</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell align="center">Email verified</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell align="right" sx={{ textAlign: "center" }}>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.map(field => (
                                        <MemberRow key={field._id} id={field._id} field={field} />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>No members found</Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    onClick={() => setMemberState(prevState => ({ ...prevState, isAdding: true }))}
                >
                    Add member
                </Button>
            </Grid>
            <MembersModal memberState={memberState} setMemberState={setMemberState} />
        </BaseStyled.Content>
    );
};

export default Members;
