import api from "@/config/api";
import { Forms } from "@/types/form";
import { log } from "@/utils/logger";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";

const useSubmit = () => {
    const { oid } = useParams();
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (form: Forms["Subscription"]) => {
        try {
            form.media.storage = form.media.storage + "GB";
            
            setLoading(true);
            await api.put(`/organizations/${oid}/subscription`, { data: form });
            await queryClient.refetchQueries({ queryKey: ["organization", oid], exact: true });

            log.success("Subscription has been successfully updated.");
        } catch (e) {
            log.error(e);
        } finally {
            setLoading(false);
        }
    };

    return {
        onSubmit,
        loading,
    };
};

export { useSubmit };
