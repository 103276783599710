import Layout from "@/components/layout";
import Brand from "@/pages/brand";
import Login from "@/pages/login";
import Organization from "@/pages/organization";
import Organizations from "@/pages/organizations";
import Products from "@/pages/products";
import storage from "@/utils/storage";
import React from "react";
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

const oid = storage.getItem("oid");
const path = oid ? `/organization/${oid}` : "/organizations";

const router = createBrowserRouter(
    createRoutesFromElements(
        <React.Fragment>
            <Route path="/sign-in" element={<Login />} />
            <Route element={<Layout />}>
                <Route path="/" element={<Navigate to={path} replace />} />
                <Route path="/organizations" element={<Organizations />} />
                <Route path="/organization/:oid">
                    <Route path="" element={<Organization />} />
                    <Route path="brand">
                        <Route path="" element={<Brand />} />
                        <Route path=":bid" element={<Brand />} />
                    </Route>
                    <Route path="brand/:bid/products" element={<Products />} />
                </Route>
            </Route>
        </React.Fragment>,
    ),
);

export default router;
