import { Grid, styled } from "@mui/material";

export const Styled = {
    Content: styled(Grid)`
        background-color: ${props => props.theme.palette.secondary.dark};
        border: 1px solid ${props => props.theme.palette.secondary.main};
        border-radius: 16px;
        padding: 32px;
        transition: border-color 0.5s ease, color 0.5s ease;

        &:hover {
            border-color: ${props => props.theme.palette.secondary.light};
        }

        ${props => props.theme.breakpoints.down("md")} {
            border-radius: unset;
        }

        & .MuiTableContainer-root {
            border: 1px solid ${props => props.theme.palette.secondary.light};
            border-radius: 8px;
            background-color: unset;
            width: 700px;

            & .MuiTableHead-root {
                background-color: ${props => props.theme.palette.secondary.main};

                & .MuiTableCell-root {
                    padding: 8px 16px;
                }
            }
        }
    `,
    Item: styled(Grid)`
        border-radius: 8px;
        height: 276px;
        min-width: 230px;
        max-width: 230px;
        background-color: ${props => props.theme.palette.secondary.main};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        &:hover {
            .cog {
                visibility: visible;
                opacity: 1;
            }
        }
    `,
    Logo: styled("img")`
        width: 150px;
        aspect-ratio: 1.1;
        margin-bottom: 20px;
    `,
    LogoPlaceholder: styled("div")`
        min-height: 150px;
        min-width: 150px;
        border-radius: 75px;
        margin-bottom: 20px;
        background-color: gray;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    Cog: styled("div")`
        height: 36px;
        width: 40px;
        background-color: ${props => props.theme.palette.secondary.light};
        position: absolute;
        top: 2.5%;
        right: 2.5%;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s all ease-in;
        z-index: 9;
        &:hover {
            background-color: ${props => props.theme.palette.primary.main};
        }
    `,
};
