import { Brand } from "@/types/entities";
import { Forms } from "@/types/form";
import { SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import {
    FieldArrayWithId,
    UseFieldArrayRemove,
    UseFieldArrayUpdate,
    UseFormTrigger,
} from "react-hook-form";

const useEntity = ({
    field,
    remove,
    update,
    setEditStatus,
    trigger,
}: {
    field: FieldArrayWithId<Forms["Brand"], "entities", "id">;
    remove: UseFieldArrayRemove;
    update: UseFieldArrayUpdate<Forms["Brand"], "entities">;
    setEditStatus: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
    trigger: UseFormTrigger<Forms["Brand"]>;
}) => {
    const [business, setBusiness] = useState(field.business);
    const [title, setTitle] = useState(field.title);
    const [countries, setCountries] = useState(field.countries);
    const [websiteURL, setWebsiteURL] = useState(field.websiteURL ?? "");

    const onRemove = (index: number) => remove(index);

    const onUpdate = async (index: number) => {
        update(index, { business, title, countries, ...(websiteURL && { websiteURL }) });

        const isValid = await trigger(`entities.${index}`);
        if (!isValid) return;

        setEditStatus(state => ({ ...state, [index]: false }));
    };

    const onChangeCountries = (e: SelectChangeEvent<unknown>) => {
        const newCountries = e.target.value as Required<Brand>["entities"][number]["countries"];

        if (countries.length === 1 && countries[0] === "ALL" && newCountries.includes("ALL")) {
            newCountries.splice(newCountries.indexOf("ALL"), 1);
            return setCountries(newCountries);
        }

        if (!newCountries.includes("ALL") && newCountries.includes("ALL")) {
            return setCountries(["ALL"]);
        }
        setCountries(newCountries);
    };

    return {
        business,
        setBusiness,
        title,
        setTitle,
        countries,
        onChangeCountries,
        websiteURL,
        setWebsiteURL,
        onRemove,
        onUpdate,
    };
};

export { useEntity };
