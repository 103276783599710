import api from "@/config/api";
import { Forms } from "@/types/form";
import { Media } from "@/types/generated/product";
import { Params } from "@/types/utils";
import { log } from "@/utils/logger";
import { validateFileSize, validateFileType } from "@/utils/validations";
import { Dispatch, SetStateAction, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { useParams } from "react-router-dom";

const useUpload = (setValue: UseFormSetValue<Forms["Brand"]>) => {
    const [loading, setLoading] = useState(false);
    const { oid } = useParams<Params>();

    const onUpload = async (
        e: React.ChangeEvent<HTMLInputElement>,
        setAnchorEl?: Dispatch<SetStateAction<HTMLElement | null>>,
    ) => {
        try {
            if (setAnchorEl) {
                setAnchorEl(null);
            }

            const file = e.target.files?.[0];
            if (!file) return;

            setLoading(true);
            validateFileSize(file);
            validateFileType(file);

            const buffer = await file.arrayBuffer();
            const blob = new Blob([buffer], { type: file.type });

            const data = new FormData();
            data.append("photo", blob, file.name);

            const media = await api.post<Required<Media>>(`/organizations/${oid}/upload`, { data });

            setValue("media", media, { shouldDirty: true });
        } catch (e) {
            log.error(e);
        } finally {
            setLoading(false);
        }
    };

    const onRemove = () => {
        setValue("media", undefined, { shouldDirty: true });
    };

    return { onUpload, onRemove, loading };
};

export { useUpload };
