import React from "react";
import { useParams } from "react-router-dom";
import { Styled } from "./styles";
import { Params } from "@/types/utils";
import BrandForm from "@/components/organization/brands/form";
import useQueryBrands from "@/hooks/queries/useQueryBrands";

const Brand: React.FC = () => {
    const { bid } = useParams<Params>();
    const { data } = useQueryBrands();

    const brand = bid && data ? data.find(i => i._id === bid) : undefined;

    return (
        <Styled.Container>
            <BrandForm brand={brand} />
        </Styled.Container>
    );
};

export default Brand;
