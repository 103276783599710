import { Grid, Typography } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { Styled } from "../styles";
import constant from "@/utils/constant";
import { RichText } from "@dippi/ui";
import { Forms } from "@/types/form";

const Description: React.FC = () => {
    const { control } = useFormContext<Forms["Brand"]>();
    const SPACING = constant.GRID_SPACING;

    return (
        <Styled.Container item container xs={12} gap={SPACING}>
            <Grid item xs={12}>
                <Typography variant="h3">Description</Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <RichText
                            onChange={field.onChange}
                            description={field.value}
                            fieldType="brand"
                        />
                    )}
                />
            </Grid>
        </Styled.Container>
    );
};

export default Description;
