import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Styled } from "../styles";
import { Subscription } from "@/types/entities";
import constant from "@/utils/constant";

interface Props {
    usage: Subscription["usage"];
}

const UsageForm: React.FC<Props> = ({ usage }) => {
    const { labels, brands } = usage;

    return (
        <Grid container item component="form">
            <Styled.Content item container xs={12} gap={constant.GRID_SPACING}>
                <Grid item xs={12}>
                    <Typography variant="h3">Usage information</Typography>
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <TextField
                        value={labels.available}
                        label="Available labels"
                        id="usage-available-labels"
                        fullWidth={true}
                        disabled={true}
                    />
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <TextField
                        value={labels.used}
                        label="Used labels"
                        id="usage-used-labels"
                        fullWidth={true}
                        disabled={true}
                    />
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <TextField
                        value={brands.available}
                        label="Available brands"
                        id="usage-available-brands"
                        fullWidth={true}
                        disabled={true}
                    />
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <TextField
                        value={brands.used}
                        label="Used brands"
                        id="usage-used-brands"
                        fullWidth={true}
                        disabled={true}
                    />
                </Grid>
            </Styled.Content>
        </Grid>
    );
};

export default UsageForm;
