import React from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Swap } from "@dippi/icons";
import constant from "@/utils/constant";
import storage from "@/utils/storage";
import { Styled } from "./styles";
import Members from "@/components/organization/members";
import Brands from "@/components/organization/brands/list";
import SubscriptionForm from "@/components/organization/subscription";
import UsageForm from "@/components/organization/usage";
import SettingsForm from "@/components/organization/settings";
import useQueryOrganization from "@/hooks/queries/useQueryOrganization";
import useQueryBrands from "@/hooks/queries/useQueryBrands";
import useQueryMembers from "@/hooks/queries/useQueryMembers";

const Organization: React.FC = () => {
    const { data: organizationData, isLoading: isLoadingOrganization } = useQueryOrganization();
    const { data: brandsData, isLoading: isLoadingBrands } = useQueryBrands();
    const { data: membersData, isLoading: isLoadingMembers } = useQueryMembers();
    const navigate = useNavigate();

    if (isLoadingOrganization || isLoadingBrands || isLoadingMembers) {
        return (
            <Styled.Container>
                <CircularProgress />
            </Styled.Container>
        );
    }

    if (!organizationData || !brandsData || !membersData) {
        return null;
    }

    const { organization, subscription } = organizationData;

    const onSwitch = () => {
        storage.removeItem("oid");
        navigate("/organizations");
    };

    return (
        <Styled.Container>
            <Grid item xs={12} display="flex" alignItems="center" gap={1} marginBottom={1}>
                <Typography variant="h1" margin="unset">
                    {organization.title}
                </Typography>
                <Styled.Button variant="text" onClick={onSwitch}>
                    <Swap />
                </Styled.Button>
            </Grid>
            <Grid item container xl={9} gap={constant.GRID_SPACING}>
                <SettingsForm organization={organization} />
                <SubscriptionForm subscription={subscription} />
                <UsageForm usage={subscription.usage} />
                <Members data={membersData} />
                <Brands data={brandsData} />
            </Grid>
        </Styled.Container>
    );
};

export default Organization;
