import { Button } from "@dippi/ui";
import { styled } from "@mui/material";

export const Styled = {
    Container: styled("div")`
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        ${props => props.theme.breakpoints.down("md")} {
            padding: 20px 0;
        }
    `,
    Button: styled(Button)`
        & svg {
            transition: fill 0.2s ease-in-out;
        }
        :hover {
            & svg {
                fill: ${props => props.theme.palette.primary.main};
            }
        }
    `,
};
