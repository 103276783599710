import { ChangeCircleWhite, DeleteWhite, Camera } from "@dippi/icons";
import { Box, CircularProgress, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Styled } from "./styles";
import { useUpload } from "./hooks";
import { useFormContext } from "react-hook-form";
import { Forms } from "@/types/form";

const FileUpload: React.FC = () => {
    const { setValue, watch } = useFormContext<Forms["Brand"]>();
    
    const { onUpload, onRemove, loading } = useUpload(setValue);
    const file = watch("media");

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onCloseMenu = () => setAnchorEl(null);

    const onResetInput = () => {
        if (inputRef.current && inputRef.current.value) {
            inputRef.current.value = "";
        }
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    if (loading) {
        return (
            <Styled.Container>
                <CircularProgress />
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {file ? (
                    <Styled.Placeholder
                        data-testid="image-upload-placeholder"
                        type="button"
                        disableRipple={true}
                        variant="text"
                        onClick={onOpenMenu}
                    >
                        <Styled.Image src={file.variants?.[2]?.url} />
                        <Styled.IconOverlay style={{ opacity: isHovered ? 1 : 0 }}>
                            <Camera style={{ opacity: isHovered ? 1 : 0 }} />
                        </Styled.IconOverlay>
                    </Styled.Placeholder>
                ) : (
                    <Styled.Placeholder
                        component="label"
                        type="button"
                        disableRipple={true}
                        variant="text"
                    >
                        <Styled.FileInput
                            data-testid="brand-image-upload-input"
                            ref={inputRef}
                            type="file"
                            accept="image/png, image/jpeg, image/webp"
                            onChange={onUpload}
                            onClick={onResetInput}
                        />
                        <Camera />
                    </Styled.Placeholder>
                )}
            </Box>
            <Styled.Feedback>
                <Typography style={{ opacity: 0.5 }} variant="body2">
                    {" "}
                    *Image size shouldn't exceed 5MBs
                </Typography>
            </Styled.Feedback>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onCloseMenu}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                slotProps={{ paper: { sx: { mt: 0.3 } } }}
            >
                <Styled.MenuItem>
                    <ListItemIcon>
                        <ChangeCircleWhite />
                    </ListItemIcon>
                    <Styled.ChangeButton component="label" type="button" variant="text">
                        <Typography fontWeight={400}>Change photo</Typography>
                        <Styled.FileInput
                            data-testid="image-change-input"
                            ref={inputRef}
                            type="file"
                            name="photo"
                            accept="image/png, image/jpeg, image/webp"
                            onChange={e => onUpload(e, setAnchorEl)}
                            onClick={onResetInput}
                        />
                    </Styled.ChangeButton>
                </Styled.MenuItem>
                <MenuItem
                    data-testid="image-remove-input"
                    onClick={() => {
                        onRemove(), setAnchorEl(null);
                    }}
                >
                    <ListItemIcon>
                        <DeleteWhite />
                    </ListItemIcon>
                    <Typography fontWeight={400}>Remove</Typography>
                </MenuItem>
            </Menu>
        </Styled.Container>
    );
};

export default FileUpload;
