import { Organization } from "@/types/utils";
import { create } from "zustand";

interface initialState {
    organizations: Organization[];
}

interface Actions {
    setOrganizations: (organizations: Organization[]) => void;
}

const initialState: initialState = {
    organizations: [],
};

export const useOrganizations = create<initialState & Actions>(set => ({
    ...initialState,
    setOrganizations: organizations => set({ organizations }),
}));
