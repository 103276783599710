import { Forms } from "@/types/form";
import { Alert, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
    Control,
    FieldArrayWithId,
    useFieldArray,
    UseFieldArrayRemove,
    UseFieldArrayUpdate,
    UseFormTrigger,
    useFormContext,
} from "react-hook-form";
import constant from "@/utils/constant";
import { AddPurple, Delete } from "@dippi/icons";
import { Styled } from "./styles";
import { Styled as Base } from "../styles";
import { useEntity } from "./hooks";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CheckIcon from "@mui/icons-material/Check";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { SELECT_ITEMS } from "@/utils/helpers";
import { Brand } from "@/types/entities";
import { LinksSelect, UncontrolledInput } from "@dippi/ui";

type BusinessType = Required<Brand>["entities"][number]["business"];
type CountryType = Required<Brand>["entities"][number]["countries"];

const entity = {
    business: "importer" as BusinessType,
    title: "",
    countries: ["GE"] as CountryType,
    websiteURL: "",
};

const Entities: React.FC = () => {
    const [editStatus, setEditStatus] = useState<Record<number, boolean>>({});

    const { control, trigger } = useFormContext<Forms["Brand"]>();
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "entities",
    });

    const onAdd = () => {
        const index = fields.length;
        setEditStatus(state => ({ ...state, [index]: true }));
        append(entity);
    };

    return (
        <Base.Container item container xs={12} gap={constant.GRID_SPACING}>
            <Grid item xs={12}>
                <Typography variant="h3">Associated partners</Typography>
            </Grid>
            <Grid item md={12} lg={9}>
                <Alert severity="info">
                    Here you can input Producer, Importer or Distributor of your brand for selected
                    countries. If no partners are selected here, it will default to your
                    organization. You can configure the type of business on the organization
                    settings page.
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <Button type="button" variant="outlined" onClick={onAdd} startIcon={<AddPurple />}>
                    Add partner
                </Button>
            </Grid>
            <Grid container>
                <Grid item container xs={12} display="flex" alignItems="center">
                    <Styled.TableHeader item xs={2}>
                        <Typography variant="body2" fontWeight="bold">
                            Business
                        </Typography>
                    </Styled.TableHeader>
                    <Styled.TableHeader item xs={3}>
                        <Typography variant="body2" fontWeight="bold">
                            Name
                        </Typography>
                    </Styled.TableHeader>
                    <Styled.TableHeader item xs={3}>
                        <Typography variant="body2" fontWeight="bold">
                            Countries
                        </Typography>
                    </Styled.TableHeader>
                    <Styled.TableHeader item xs={3}>
                        <Typography variant="body2" fontWeight="bold">
                            Website
                        </Typography>
                    </Styled.TableHeader>
                </Grid>
                {fields.map((field, index) => {
                    return (
                        <Entity
                            key={field.id}
                            field={field}
                            index={index}
                            remove={remove}
                            update={update}
                            control={control}
                            editStatus={editStatus}
                            setEditStatus={setEditStatus}
                            trigger={trigger}
                        />
                    );
                })}
            </Grid>
        </Base.Container>
    );
};

export default Entities;

interface EntityProps {
    field: FieldArrayWithId<Forms["Brand"], "entities", "id">;
    index: number;
    remove: UseFieldArrayRemove;
    update: UseFieldArrayUpdate<Forms["Brand"], "entities">;
    control: Control<Forms["Brand"]>;
    editStatus: Record<number, boolean>;
    setEditStatus: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
    trigger: UseFormTrigger<Forms["Brand"]>;
}

const Entity: React.FC<EntityProps> = props => {
    const { field, index, remove, update, control, editStatus, setEditStatus, trigger } = props;
    const theme = useTheme();

    const editting = editStatus[index];
    const {
        business,
        setBusiness,
        countries,
        onChangeCountries,
        title,
        setTitle,
        websiteURL,
        setWebsiteURL,
        onRemove,
        onUpdate,
    } = useEntity({
        field,
        remove,
        update,
        setEditStatus,
        trigger,
    });

    const titleError = control._formState.errors.entities?.[index]?.title;
    const countriesError = control._formState.errors.entities?.[index]?.countries;
    const websiteURLError = control._formState.errors.entities?.[index]?.websiteURL;

    const isError = Object.values(control._formState.errors.entities?.[index] ?? {}).length > 0;

    return (
        <Grid item container xs={12} display="flex" alignItems="center">
            <Styled.TableItem item xs={2} validations={{ isError }}>
                <LinksSelect
                    value={business}
                    id={`brand-entity-business-select-${index}`}
                    variant="standard"
                    fullWidth={true}
                    disabled={!editting}
                    onChange={e => setBusiness(e.target.value as BusinessType)}
                    items={SELECT_ITEMS.business}
                />
            </Styled.TableItem>
            <Styled.TableItem item xs={3} validations={{ isError, titleError: !!titleError }}>
                <UncontrolledInput
                    id={`brand-entity-title-${index}`}
                    type="text"
                    value={title}
                    disabled={!editting}
                    onChange={e => setTitle(e.target.value)}
                    fullWidth={true}
                    // @ts-expect-error
                    error={titleError?.message}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    autoFocus={true}
                />
            </Styled.TableItem>
            <Styled.TableItem
                item
                xs={3}
                validations={{ isError, countriesError: !!countriesError }}
            >
                <LinksSelect
                    value={countries}
                    id={`brand-entity-country-select-${index}`}
                    variant="standard"
                    multiple={true}
                    fullWidth={true}
                    disabled={!editting}
                    onChange={onChangeCountries}
                    items={[{ label: "All countries", value: "ALL" }, ...SELECT_ITEMS.countries]}
                />
            </Styled.TableItem>
            <Styled.TableItem
                item
                xs={3}
                validations={{ isError, websiteURLError: !!websiteURLError }}
                onClick={() => (!editting && websiteURL ? window.open(websiteURL, "_blank") : null)}
            >
                <UncontrolledInput
                    id={`brand-entity-websiteURL-${index}`}
                    type="url"
                    value={websiteURL}
                    onChange={e => setWebsiteURL(e.target.value)}
                    disabled={!editting}
                    fullWidth={true}
                    // @ts-expect-error
                    error={websiteURLError?.message}
                    variant="standard"
                    placeholder="Website URL"
                    sx={
                        !editting && websiteURL
                            ? {
                                  "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
                                      WebkitTextFillColor: `${theme.palette.primary.main}`,
                                      pointerEvents: "none",
                                      paddingRight: "10px",
                                  },
                                  "& :hover": {
                                      cursor: "pointer",
                                  },
                              }
                            : null
                    }
                    InputProps={{
                        disableUnderline: true,
                        endAdornment:
                            !editting && websiteURL ? (
                                <OpenInNewIcon
                                    fontSize="small"
                                    color="primary"
                                    sx={{ cursor: "pointer" }}
                                />
                            ) : null,
                    }}
                />
            </Styled.TableItem>
            <Styled.TableItemActions item xs={1} gap={1} validations={{ isError }}>
                {editting ? (
                    <CheckIcon
                        color="success"
                        onClick={onUpdate.bind(this, index)}
                        sx={{ cursor: "pointer" }}
                    />
                ) : (
                    <BorderColorIcon
                        onClick={() => setEditStatus(state => ({ ...state, [index]: true }))}
                        color="action"
                        sx={{ cursor: "pointer" }}
                    />
                )}
                <Delete onClick={onRemove.bind(this, index)} style={{ cursor: "pointer" }} />
            </Styled.TableItemActions>
        </Grid>
    );
};
