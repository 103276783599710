import api from "@/config/api";
import { Organization } from "@/types/entities";
import { Forms } from "@/types/form";
import { log } from "@/utils/logger";
import { useQueryClient } from "@tanstack/react-query";

const useSubmit = (resetAndClose: () => void) => {
    const queryClient = useQueryClient();

    const onSubmit = async (data: Forms["Organization"] | null) => {
        try {
            if (!data) return;

            const organization = {
                title: data.title,
                industry: data.industry,
                business: data.business,
                companyId: data.companyId,
                phone: data.phone,
                email: data.email,
                address: {
                    country: data.country,
                    city: data.city,
                    street: data.street,
                    ...(data.zipCode && { zipCode: data.zipCode }),
                },
            };
            await api.post<Organization>("/organizations", {
                data: { organization },
            });

            log.success(`Organization ${data.title} has been successfully created.`);

            queryClient.invalidateQueries({ queryKey: ["organizations"] });
            resetAndClose();
        } catch (e) {
            log.error(e);
        }
    };

    return onSubmit;
};

export { useSubmit };
