import { Grid, styled } from "@mui/material";

export const Styled = {
    Form: styled("form")`
        padding: 32px 32px 132px 32px;
        width: 90%;
        ${props => props.theme.breakpoints.down("md")} {
            width: 100%;
        }
        ${props => props.theme.breakpoints.down("md")} {
            padding: 24px 20px 24px 20px;
        }

        ${props => props.theme.breakpoints.down("sm")} {
            padding: 14px 10px 14px 10px;
        }
    `,
    Container: styled(Grid)`
        background-color: ${props => props.theme.palette.secondary.dark};
        border: 1px solid ${props => props.theme.palette.secondary.main};
        border-radius: 16px;
        padding: 32px;
        transition: border-color 0.5s ease, color 0.5s ease;

        &:hover {
            border-color: ${props => props.theme.palette.secondary.light};
        }

        ${props => props.theme.breakpoints.down("md")} {
            border-radius: unset;
        }
    `,
    ImageContainer: styled(Grid)`
        height: auto, ${props => props.theme.breakpoints.down("md")} {
            margin: auto;
        }
    `,
};
