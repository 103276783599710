import React from "react";
import { Styled } from "../../styles";
import { Grid, Typography } from "@mui/material";
import constant from "@/utils/constant";
import { isArrayWithData, isDefined, isString } from "@/utils/validations";
import { useNavigate, useParams } from "react-router-dom";
import { Brand } from "@/types/entities";
import { shorten } from "@/utils/helpers";
import { Button } from "@dippi/ui";
import SettingsIcon from '@mui/icons-material/Settings';
import { Params } from "@/types/utils";
import storage from "@/utils/storage";
import useQueryOrganization from "@/hooks/queries/useQueryOrganization";

interface Props {
    data: Array<Partial<Brand>>;
}

const Brands: React.FC<Props> = ({ data }) => {
    const navigate = useNavigate();
    const { oid, bid } = useParams<Params>();
    const { data: organizationData } = useQueryOrganization();

    const usage = organizationData?.subscription?.usage;

    const limit = !bid && isDefined(usage?.brands?.available) && usage.brands.available === 0;

    const onSelectBrand = (id: string) => {
        navigate(`/organization/${oid}/brand/${id}/products`);
    };

    const onAddBrand = () => {
        navigate(`/organization/${oid}/brand`);
    };

    const onOpenSettings = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        bid: string | undefined,
    ) => {
        e.stopPropagation();

        if (!isString(bid)) return;

        storage.setItem("bid", bid);
        navigate(`/organization/${oid}/brand/${bid}`);
    };

    return (
        <Styled.Content item container xs={12} gap={constant.GRID_SPACING}>
            <Grid item xs={12}>
                <Typography variant="h2" margin="12px 0px">
                    Brands
                </Typography>
                {limit && (
                    <Typography variant="body1" color="error">
                        The allowed brand limit has been reached.
                    </Typography>
                )}
            </Grid>
            {isArrayWithData(data) ? (
                <Grid container item xs={12} gap={constant.GRID_SPACING * 2}>
                    {data.map((i: Partial<Brand>) => {
                        const media = i.media;

                        const title = i.title as string;

                        const url: string | undefined = media && media.variants?.[3]?.url;

                        return (
                            <Styled.Item item onClick={() => onSelectBrand(i._id as string)} key={i._id}>
                                {url ? (
                                    <Styled.Logo src={url} alt={title} />
                                ) : (
                                    <Styled.LogoPlaceholder>
                                        <Typography
                                            variant="h2"
                                            marginBottom={0}
                                            textTransform="uppercase"
                                        >
                                            {title[0]}
                                        </Typography>
                                    </Styled.LogoPlaceholder>
                                )}
                                <Typography
                                    textAlign="center"
                                    width="100%"
                                    sx={{ wordWrap: "break-word" }}
                                >
                                    {shorten(title, 35)}
                                </Typography>
                                <Styled.Cog onClick={e => onOpenSettings(e, i._id)} className="cog">
                                    <SettingsIcon />
                                </Styled.Cog>
                            </Styled.Item>
                        );
                    })}
                </Grid>
            ) : (
                <Typography>No brands found</Typography>
            )}
            <Grid item xs={12}>
                <Button variant="outlined" disabled={limit} onClick={onAddBrand}>
                    Add brand
                </Button>
            </Grid>
        </Styled.Content>
    );
};

export default Brands;
