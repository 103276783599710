import api from "@/config/api";
import { useAdmin } from "@/context/admin";
import { useOrganizations } from "@/context/organizations";
import { Organization } from "@/types/utils";
import { log } from "@/utils/logger";
import { useQuery } from "@tanstack/react-query";

const useQueryOrganizations = () => {
    const authenticated = useAdmin(state => state.authenticated);
    const setOrganizations = useOrganizations(state => state.setOrganizations);

    const getOrganizations = async () => {
        try {
            const organizations = await api.get<Array<Organization>>("/organizations");

            setOrganizations(organizations);

            return organizations;
        } catch (e) {
            log.error(e);
        }
    };

    return useQuery({
        queryKey: ["organizations"],
        queryFn: getOrganizations,
        enabled: authenticated,
    });
};

export default useQueryOrganizations;
