import { Forms } from "@/types/form";
import { FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";

const useSocials = (
    watch: UseFormWatch<Forms["Brand"]>,
    setValue: UseFormSetValue<Forms["Brand"]>,
    errors: FieldErrors<Forms["Brand"]>,
) => {
    const socials = watch("socials");

    const instagram = (socials?.find(i => i.includes("instagram")) ?? "").replace(
        /https:\/\/(www\.)?instagram.com\//,
        "",
    );
    const facebook = (socials?.find(i => i.includes("facebook")) ?? "").replace(
        /https:\/\/(www\.)?facebook.com\//,
        "",
    );
    const tiktok = (socials?.find(i => i.includes("tiktok")) ?? "").replace(
        /https:\/\/(www\.)?tiktok.com\//,
        "",
    );
    const youtube = (socials?.find(i => i.includes("youtube")) ?? "").replace(
        /https:\/\/(www\.)?youtube.com\//,
        "",
    );
    const linkedin = (socials?.find(i => i.includes("linkedin")) ?? "").replace(
        /https:\/\/(www\.)?linkedin.com\/company\//,
        "",
    );

    const onChangeSocial = (prefix: string, username: string) => {
        const url = prefix + username;

        if (!socials) {
            return setValue("socials", [url], { shouldValidate: false });
        }

        const index = socials.findIndex(i => i.includes(prefix));
        const newSocials = [...socials];

        if (index === -1) {
            newSocials.push(url);
        } else if (username.length === 0) {
            newSocials.splice(index, 1);
        } else {
            newSocials[index] = url;
        }

        setValue("socials", newSocials, { shouldValidate: true, shouldDirty: true });
    };

    const getSocialValidationMessage = (social: string): string | undefined => {
        const socialIndex = socials?.findIndex(s => s.includes(social)) as number;
        return socialIndex !== -1
            ? (errors?.socials?.[socialIndex]?.message as string | undefined)
            : undefined;
    };  

    return {
        instagram,
        facebook,
        tiktok,
        youtube,
        linkedin,
        socials,
        onChangeSocial,
        getSocialValidationMessage,
    };
};

export { useSocials };
