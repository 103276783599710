import * as Yup from "yup";

const isString = (val?: unknown): val is string => {
    return typeof val === "string" && val.length > 0;
};

const isNumber = (val?: unknown): val is number => {
    return typeof val === "number";
};

const isObject = (val: unknown): val is object => {
    return typeof val === "object" && val !== null;
};

const isDefined = <D>(val: D | undefined): val is D => {
    return typeof val !== "undefined";
};

const hasValue = <D>(val: D | null | undefined): val is D => {
    return typeof val !== "undefined" && val !== null;
};

const isArrayWithData = <D>(val: D | undefined): val is D => {
    return Array.isArray(val) && val.length > 0;
};

const isJson = (val: string) => {
    try {
        JSON.parse(val);
        return true;
    } catch (e) {
        return false;
    }
};

const typedKeys = <O extends object>(obj: O) => {
    return Object.keys(obj) as (keyof O)[];
};

Yup.addMethod(Yup.mixed, "validateSubscriptionStats", function (this: Yup.MixedSchema) {
    return this.test("is-valid", "Must be number or unlimited", function (this, value) {
        if (value && !isNaN(+value)) return true;
        if (value && isNaN(+value) && value !== "unlimited") return false;
        return true;
    });
});

const validateFileSize = (file: File) => {
    const sizeInKB = Math.ceil(file.size / 1024);
    if (sizeInKB > 5000) throw Error("Maximum allowed image size is 5 MBs. $sentry-ignore");
};

const validateFileType = (file: File) => {
    const type = file.type;
    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];

    if (allowedTypes.includes(type) === false) {
        throw new Error(
            "Invalid image type. Allowed types are jpeg/jpg, png, webp. $sentry-ignore",
        );
    }
};


export { isString, isNumber, isObject, isDefined, hasValue, isArrayWithData, isJson, typedKeys, validateFileSize, validateFileType };
