import { Organization } from "@/types/entities";
import constant from "@/utils/constant";
import { Button, Grid, Typography } from "@mui/material";
import { Styled } from "../styles";
import React from "react";
import { Forms } from "@/types/form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import schemas from "@/utils/schemas";
import { Input, Select } from "@dippi/ui";
import { SELECT_ITEMS } from "@/utils/helpers";
import { useSubmit } from "./hooks";

interface Props {
    organization: Organization;
}

const SettingsForm: React.FC<Props> = ({ organization }) => {
    const { title, companyId, business, industry, email, phone, address } = organization;

    const methods = useForm<Forms["Settings"]>({
        resolver: yupResolver(schemas.settings),
        values: { title, companyId, business, industry, email, phone, address },
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = methods;

    const { loading, onSubmit } = useSubmit();

    return (
        <FormProvider {...methods}>
            <Grid
                container
                item
                component="form"
                onSubmit={handleSubmit(async form => await onSubmit(form))}
            >
                <Styled.Content item container xs={12} gap={constant.GRID_SPACING}>
                    <Grid item xs={12}>
                        <Typography variant="h3">Company information</Typography>
                    </Grid>
                    <Grid item xl={3} md={5} sm={5} xs={12}>
                        <Input
                            control={control}
                            name="title"
                            label="Title"
                            id="org-title"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xl={3} md={5} sm={5} xs={12}>
                        <Input
                            control={control}
                            name="companyId"
                            label="Organization ID"
                            id="org-id"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xl={3} md={5} sm={5} xs={12}>
                        <Select
                            control={control}
                            name="business"
                            label="Line of business"
                            id="org-business"
                            items={SELECT_ITEMS.business}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xl={3} md={5} sm={5} xs={12}>
                        <Select
                            control={control}
                            name="industry"
                            label="Industry"
                            id="org-industry"
                            items={SELECT_ITEMS.industries}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xl={3} md={5} sm={5} xs={12}>
                        <Input
                            control={control}
                            name="email"
                            label="email"
                            id="org-email"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xl={3} md={5} sm={5} xs={12}>
                        <Input
                            control={control}
                            name="phone"
                            label="Phone"
                            id="org-phone"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item container xs={12} gap={constant.GRID_SPACING}>
                        <Grid item xl={3} md={5} sm={5} xs={12}>
                            <Select
                                control={control}
                                name="address.country"
                                label="Country"
                                id="org-country"
                                items={SELECT_ITEMS.countries}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xl={3} md={5} sm={5} xs={12}>
                            <Input
                                control={control}
                                name="address.street"
                                label="Street"
                                id="org-street"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xl={3} md={5} sm={5} xs={12}>
                            <Input
                                control={control}
                                name="address.city"
                                label="City"
                                id="org-city"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xl={3} md={5} sm={5} xs={12}>
                            <Input
                                control={control}
                                name="address.zipCode"
                                label="Post code"
                                id="org-zip-code"
                                fullWidth={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} gap={constant.GRID_SPACING}>
                        <Button type="submit" disabled={loading || !isDirty}>
                            Save
                        </Button>
                        <Button type="button" variant="outlined" onClick={() => reset()}>
                            Cancel
                        </Button>
                    </Grid>
                </Styled.Content>
            </Grid>
        </FormProvider>
    );
};

export default SettingsForm;
